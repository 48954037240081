import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragAndDropFileSelectDirective } from './directives/drag-and-drop-file-select.directive';
import { QuillModule } from "ngx-quill";
import { NgxUiLoaderConfig, NgxUiLoaderModule } from "ngx-ui-loader";

import { ExtractIndustriesPipe } from './pipes/extract-industries.pipe';
import { DashboardCommonComponent } from './common-components/startup-investor-dashboard-common/dashboard-common.component';
import { DashboardCommonCalenderComponent } from './common-components/startup-investor-dashboard-common-calender/dashboard-common-calender.component';
import { NgbAccordionModule, NgbAlertModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { StartupInvestorMonthlyInvestmentComponent } from './common-components/startup-investor-monthly-investment/startup-investor-monthly-investment.component';
import { AvatarModule } from 'ngx-avatars';
import { StartupInvestorDashboardProfileComponent } from './common-components/startup-investor-dashboard-profile/startup-investor-dashboard-profile.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { StartupInvestorCompareComponent } from './common-components/startup-investor-compare/startup-investor-compare.component';
import { RouterModule } from '@angular/router';
import { StartupCompareCardComponent } from './common-components/startup-investor-compare/startup-compare-card/startup-compare-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CongratulationsAnimationComponent } from './common-components/congratulations-animation/congratulations-animation.component';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { UserProfileMenuComponent } from './common-components/user-profile-menu/user-profile-menu.component';
import { OffcanvasSidebarComponent } from './common-components/offcanvas-sidebar/offcanvas-sidebar.component';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';
import { ComingSoonComponent } from './common-components/coming-soon/coming-soon.component';
import { NoDataComponent } from './common-components/no-data/no-data.component';
import { StartupRaiseFundSwitchComponent } from './common-components/startup-raise-fund-switch/startup-raise-fund-switch.component';
import { InvestorProvidingFundsSwitchComponent } from './common-components/investor-providing-funds-switch/investor-providing-funds-switch.component';
import { AddMeetingButtonComponent } from './common-components/add-meeting-button/add-meeting-button.component';
import { AddMeetingModalComponent } from './common-components/add-meeting-button/add-meeting-modal/add-meeting-modal.component';
import { OurInvestorsPortfolioComponent } from './common-components/our-investors-portfolio/our-investors-portfolio.component';
import { PipesModule } from './pipes/pipes.module';
import { CorporateProfileCompletenessComponent } from './common-components/corporate-profile-completeness/corporate-profile-completeness.component';
import { ConnectionCountComponent } from './common-components/connection-count/connection-count.component';
import { AddTeamButtonComponent } from './common-components/add-team-button/add-team-button.component';
import { OurPartnerComponent } from './common-components/our-partner/our-partner.component';
import { AdViewerModule } from '../modules/ad-viewer/ad-viewer.module';
import { AcceptConnectionModalComponent } from './common-components/accept-connection-modal/accept-connection-modal.component';
import { RejectConnectionModalComponent } from './common-components/reject-connection-modal/reject-connection-modal.component';
import { MultiSelectDropdownModule } from './common-components/multi-select-dropdown/multi-select-dropdown.module';
import { RejectCandidateModalComponent } from './common-components/reject-candidate-modal/reject-candidate-modal.component';
import { CalenderModule } from '../modules/calender/calender.module';
import { EditMeetingComponent } from '../modules/calender/calender-component/edit-meeting-event/edit-meeting-event.component';
import { DeleteMeetingComponent } from '../modules/calender/calender-component/delete-meeting-event/delete-meeting-event.component';
import { MeetingDetailsModalComponent } from '../modules/calender/calender-component/meeting-details-modal/meeting-details-modal.component';
import { RecommendedInvestorsComponent } from './common-components/recommended-investors/recommended-investors.component';
import { InvestorsSearchCardModule } from '../modules/search/pages/investors/components/investors-search-card/investors-search-card.module';
import { RecommendedStartupsComponent } from './common-components/recommended-startups/recommended-startups.component';
import { StartupSearchCardModule } from '../modules/search/pages/startups/components/startup-search-card/startup-search-card.module';
import { SwiperModule } from "swiper/angular";
import { DashboardRecentChallengesComponent } from './common-components/dashboard-recent-challenges/dashboard-recent-challenges.component';
import { DashboardRecentJobApplicationsComponent } from './common-components/dashboard-recent-job-applications/dashboard-recent-job-applications.component';
import { LogoRendererComponent } from './common-components/logo-renderer/logo-renderer.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MentorProfileCompletenessComponent } from './common-components/mentor-profile-completeness/mentor-profile-completeness.component';
import { ServiceProviderProfileCompletenessComponent } from './common-components/service-provider-profile-completeness/service-provider-profile-completeness.component';
import { ConnectPowerPitchModalComponent } from './common-components/connect-power-pitch-modal/connect-power-pitch-modal.component';
import { PublicProfileDynamicFormViewComponent } from './common-components/public-profile-dynamic-form-view/public-profile-dynamic-form-view.component';
import { ActiveConnectionActionsComponent } from './common-components/active-connection-actions/active-connection-actions.component';
import { FormNextStepButtonComponent } from './common-components/form-next-step-button/form-next-step-button.component';
import { EventsCalenderComponent } from './common-components/events-calender/events-calender.component';
import { EventDetailsModalComponent } from './common-components/event-details-modal/event-details-modal.component';
import { AppBlockCopyPasteDirective } from './directives/app-block-copy-paste.directive';
import { CommunityFeedStatsComponent } from './common-components/community-feed-stats/community-feed-stats.component';
import { MeetingsAndEventsComponent } from './common-components/meetings-and-events/meetings-and-events.component';
import { CommunityFeedListComponent } from './common-components/community-feed-list/community-feed-list.component';
import { SupportingDocumentsComponent } from './common-components/supporting-documents/supporting-documents.component';
import { CommunityFeedFormComponent } from './common-components/community-feed-form/community-feed-form.component';
import { CommunityFeedSinglePostComponent } from './common-components/community-feed-list/community-feed-single-post/community-feed-single-post.component';
import { FeedSingleCommentComponent } from './common-components/community-feed-list/feed-single-comment/feed-single-comment.component';
import { FeedCommentFormComponent } from './common-components/community-feed-list/feed-comment-form/feed-comment-form.component';
// import { ChallengeApplicationModalComponent } from './common-components/challenge-application-modal/challenge-application-modal.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { FeedFormImageSelectModalComponent } from './common-components/community-feed-form/feed-form-image-select-modal/feed-form-image-select-modal.component';
import { FeedFormFileSelectModalComponent } from './common-components/community-feed-form/feed-form-file-select-modal/feed-form-file-select-modal.component';
import { LightgalleryModule } from 'lightgallery/angular';
import { TwitterWidgetComponent } from './common-components/twitter-widget/twitter-widget.component';
import { NgxTwitterWidgetsModule } from 'ngx-twitter-widgets';
import { ReactionsModalComponent } from './common-components/community-feed-list/reactions-modal/reactions-modal.component';
import { RecommendedMentorsComponent } from './common-components/recommended-mentors/recommended-mentors.component';
import { MentorSearchCardModule } from '../modules/search/pages/mentor-search-page/components/mentor-search-card/mentor-search-card.module';
import { ShareProfileModalComponent } from './common-components/share-profile-modal/share-profile-modal.component';
import { PublicSharedModule } from '../modules/public-shared/public-shared.module';
import { ChangeProfileTypeModalComponent } from './common-components/change-profile-type-modal/change-profile-type-modal.component';
import { CreateStartupProfileTypeComponent } from './common-components/change-profile-type-modal/create-startup-profile-type/create-startup-profile-type.component';
import { CreateMentorProfileTypeComponent } from './common-components/change-profile-type-modal/create-mentor-profile-type/create-mentor-profile-type.component';
import { CreateCorporateProfileTypeComponent } from './common-components/change-profile-type-modal/create-corporate-profile-type/create-corporate-profile-type.component';
import { CreateInvestorProfileTypeComponent } from './common-components/change-profile-type-modal/create-investor-profile-type/create-investor-profile-type.component';
import { RateExpirenceModalComponent } from './common-components/rate-expirence-modal/rate-expirence-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommunityFeedStatsCountComponent } from './common-components/community-feed-stats-count/community-feed-stats-count.component';
import { TwitterTimelineComponent } from './common-components/twitter-timeline/twitter-timeline.component';
import { CommunityFeedReactionComponent } from './common-components/community-feed-reaction/community-feed-reaction.component';
import { VerifyMobileNumberModalComponent } from './common-components/verify-mobile-number-modal/verify-mobile-number-modal.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { PublicLayoutSidebarComponent } from '../layouts/public/public-layout-sidebar/public-layout-sidebar.component';
import { AccountSettingHeaderComponent } from '../modules/account/pages/account-setting-header/account-setting-header.component';
import { AccountSettingPageTopNavComponent } from '../modules/account/pages/edit-profile/account-setting-page-top-nav/account-setting-page-top-nav.component';
import { GlobalElasticSearchComponent } from './common-components/global-elastic-search/global-elastic-search.component';
import { LinkyModule } from 'ngx-linky';
import { FileSelectorComponent } from './common-components/community-feed-form/feed-form-image-select-modal/file-selector/file-selector.component';
import { ProgramOfficeProfileCompletenessComponent } from './common-components/program-office-profile-completeness/program-office-profile-completeness.component';
import { ConnectionReqMessageComponent } from './common-components/connection-req-message/connection-req-message.component';
import { LimitedAccessMessageBoxComponent } from './common-components/limited-access-message-box/limited-access-message-box.component';
import { EventDetailsModalWrapperComponent } from './common-components/event-details-modal-wrapper/event-details-modal-wrapper.component';
import { EventDetailsOneOnOneModalComponent } from './common-components/event-details-one-on-one-modal/event-details-one-on-one-modal.component';
import { NumberFormatDirective } from './directives/number-format.directive';
import { CertificateViewerModalComponent } from './common-components/certificate-viewer-modal/certificate-viewer-modal.component';
import { PublicProfileDynamicSingleFormViewComponent } from './common-components/public-profile-dynamic-form-view/public-profile-dynamic-single-form-view/public-profile-dynamic-single-form-view.component';
import { IpDetailsModalComponent } from './common-components/ip-details-modal/ip-details-modal.component';
import { IndividualProfileCompletenessComponent } from './common-components/individual-profile-completeness/individual-profile-completeness.component';
import { WebinarCardComponent } from './common-components/webinar-card/webinar-card.component';
import { ResourceReportCardComponent } from './common-components/resource-report-card/resource-report-card.component';
import { NewsCardComponent } from './common-components/news-card/news-card.component';
import { BookFacilityComponent } from './common-components/book-facility/book-facility.component';
import { PartnerProfileCompletenessComponent } from './common-components/partner-profile-completeness/partner-profile-completeness.component';
import { StarRatingViewerComponent } from './common-components/star-rating-viewer/star-rating-viewer.component';

export function playerFactory() {
  return player;
}
const avatarColors = ["#F1FAFF"];

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsColor: "red",
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 40,
  // bgsType: SPINNER.rectangleBounce, // background spinner type
  // fgsType: SPINNER.chasingDots, // foreground spinner type
  // pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  // pbThickness: 5, // progress bar thickness
  fgsColor: '#e9843e',
  hasProgressBar: false
};

@NgModule({
  declarations: [
    DragAndDropFileSelectDirective,
    ExtractIndustriesPipe,
    DashboardCommonComponent,
    DashboardCommonCalenderComponent,
    ExtractIndustriesPipe,
    StartupInvestorMonthlyInvestmentComponent,
    StartupInvestorDashboardProfileComponent,
    StartupInvestorCompareComponent,
    StartupCompareCardComponent,
    CongratulationsAnimationComponent,
    UserProfileMenuComponent,
    PageNotFoundComponent,
    ComingSoonComponent,
    OffcanvasSidebarComponent,
    NoDataComponent,
    StartupRaiseFundSwitchComponent,
    InvestorProvidingFundsSwitchComponent,
    AddMeetingButtonComponent,
    AddMeetingModalComponent,
    OurInvestorsPortfolioComponent,
    CorporateProfileCompletenessComponent,
    ConnectionCountComponent,
    AddTeamButtonComponent,
    OurPartnerComponent,
    AcceptConnectionModalComponent,
    RejectConnectionModalComponent,
    RejectCandidateModalComponent,

    EditMeetingComponent,
    DeleteMeetingComponent,
    MeetingDetailsModalComponent,
    RecommendedInvestorsComponent,
    RecommendedStartupsComponent,
    DashboardRecentChallengesComponent,
    DashboardRecentJobApplicationsComponent,
    LogoRendererComponent,
    MentorProfileCompletenessComponent,
    ServiceProviderProfileCompletenessComponent,
    ConnectPowerPitchModalComponent,
    PublicProfileDynamicFormViewComponent,
    ActiveConnectionActionsComponent,
    FormNextStepButtonComponent,
    EventsCalenderComponent,
    EventDetailsModalComponent,
    AppBlockCopyPasteDirective,
    CommunityFeedStatsComponent,
    MeetingsAndEventsComponent,
    CommunityFeedListComponent,
    SupportingDocumentsComponent,
    CommunityFeedFormComponent,
    CommunityFeedSinglePostComponent,
    FeedSingleCommentComponent,
    FeedCommentFormComponent,
    FeedFormImageSelectModalComponent,
    FeedFormFileSelectModalComponent,
    TwitterWidgetComponent,
    ReactionsModalComponent,
    RecommendedMentorsComponent,
    ShareProfileModalComponent,
    ChangeProfileTypeModalComponent,
    CreateStartupProfileTypeComponent,
    CreateMentorProfileTypeComponent,
    CreateCorporateProfileTypeComponent,
    CreateInvestorProfileTypeComponent,
    RateExpirenceModalComponent,
    CommunityFeedStatsCountComponent,
    TwitterTimelineComponent,
    CommunityFeedReactionComponent,
    VerifyMobileNumberModalComponent,
    PublicLayoutSidebarComponent,

    AccountSettingPageTopNavComponent,
    AccountSettingHeaderComponent,
    GlobalElasticSearchComponent,
    FileSelectorComponent,
    ProgramOfficeProfileCompletenessComponent,
    ConnectionReqMessageComponent,
    LimitedAccessMessageBoxComponent,
    EventDetailsModalWrapperComponent,
    EventDetailsOneOnOneModalComponent,
    NumberFormatDirective,
    CertificateViewerModalComponent,
    PublicProfileDynamicSingleFormViewComponent,
    IpDetailsModalComponent,
    IndividualProfileCompletenessComponent,
    WebinarCardComponent,
    ResourceReportCardComponent,
    NewsCardComponent,
    BookFacilityComponent,
    PartnerProfileCompletenessComponent,
    StarRatingViewerComponent
    // ChallengeApplicationModalComponent
  ],
  imports: [
    CommonModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['clean'],                                         // remove formatting button

          ['link']   //, 'image', 'video'                      // link and image, video
        ]
      }
    }),
    NgbModule,
    NgApexchartsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgbAlertModule,
    AvatarModule.forRoot({
      colors:avatarColors
    }),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    PipesModule,
    AdViewerModule,
    MultiSelectDropdownModule,
    InvestorsSearchCardModule,
    StartupSearchCardModule,
    SwiperModule,
    PipesModule,
    LazyLoadImageModule,
    NgbNavModule,
    InfiniteScrollModule,
    LightgalleryModule,
    NgxTwitterWidgetsModule,
    MentorSearchCardModule,
    PublicSharedModule,
    NgSelectModule,
    NgbAccordionModule,
    NgOtpInputModule,
    LinkyModule
    // CalenderModule
  ],
  exports: [
    DragAndDropFileSelectDirective,
    ExtractIndustriesPipe,
    DashboardCommonComponent,
    DashboardCommonCalenderComponent,
    StartupInvestorMonthlyInvestmentComponent,
    StartupInvestorDashboardProfileComponent,
    StartupInvestorCompareComponent,
    QuillModule,
    NgxUiLoaderModule,
    CongratulationsAnimationComponent,
    UserProfileMenuComponent,
    OffcanvasSidebarComponent,
    NoDataComponent,
    StartupRaiseFundSwitchComponent,
    InvestorProvidingFundsSwitchComponent,
    AddMeetingButtonComponent,
    OurInvestorsPortfolioComponent,
    CorporateProfileCompletenessComponent,
    ConnectionCountComponent,
    AddTeamButtonComponent,
    OurPartnerComponent,
    AppBlockCopyPasteDirective,

    EditMeetingComponent,
    DeleteMeetingComponent,
    MeetingDetailsModalComponent,
    RecommendedInvestorsComponent,
    RecommendedStartupsComponent,
    DashboardRecentChallengesComponent,
    DashboardRecentJobApplicationsComponent,
    LogoRendererComponent,
    MentorProfileCompletenessComponent,
    ServiceProviderProfileCompletenessComponent,
    PublicProfileDynamicFormViewComponent,
    ActiveConnectionActionsComponent,
    FormNextStepButtonComponent,
    EventsCalenderComponent,
    PageNotFoundComponent,
    CommunityFeedStatsComponent,
    MeetingsAndEventsComponent,
    CommunityFeedListComponent,
    SupportingDocumentsComponent,
    CommunityFeedFormComponent,
    TwitterWidgetComponent,
    RecommendedMentorsComponent,
    LazyLoadImageModule,
    LightgalleryModule,
    CommunityFeedStatsCountComponent,
    CommunityFeedSinglePostComponent,
    TwitterTimelineComponent,
    CommunityFeedReactionComponent,
    NgbAccordionModule,
    VerifyMobileNumberModalComponent,
    PublicLayoutSidebarComponent,
    AccountSettingPageTopNavComponent,
    AccountSettingHeaderComponent,
    GlobalElasticSearchComponent,
    FileSelectorComponent,
    ProgramOfficeProfileCompletenessComponent,
    ConnectionReqMessageComponent,
    LimitedAccessMessageBoxComponent,
    NumberFormatDirective,
    IndividualProfileCompletenessComponent,
    WebinarCardComponent,
    ResourceReportCardComponent,
    NewsCardComponent,
    PartnerProfileCompletenessComponent,
    StarRatingViewerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
