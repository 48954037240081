import { Action } from "@ngrx/store";
import { IGlobalSettings } from "../../domain/settings.model";
import { IBrandDetails } from "../../domain/brand.model";
import { IBasicConnectionList } from "src/app/modules/connections/connections.model";

export enum GlobalActionTypes {
  GetGlobalSettings = "[Global] GetGlobalSettings",
  GetGlobalSettingsSuccess = "[Global] GetGlobalSettingsSuccess",
  GetGlobalSettingsFault = "[Global] GetGlobalSettingsFault",

  SetBrandDetails = "[Global] SetBrandDetails",
  SetFormsList = "[Global] SetFormsList",


  GetBasicConnectionsList = "[Global] GetBasicConnectionsList",
  GetBasicConnectionsListSuccess = "[Global] GetBasicConnectionsListSuccess",
  GetBasicConnectionsListFault = "[Global] GetBasicConnectionsListFault",

}

export class GetGlobalSettings implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettings;
}

export class GetGlobalSettingsSuccess implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettingsSuccess;

  constructor(public payload: IGlobalSettings) { }
}

export class GetGlobalSettingsFault implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettingsFault;
  constructor(public payload: string) { }
}


export class SetBrandDetails implements Action {
  readonly type = GlobalActionTypes.SetBrandDetails;

  constructor(public payload: IBrandDetails) { }
}
export class SetFormsList implements Action {
  readonly type = GlobalActionTypes.SetFormsList;

  constructor(public payload: IBrandDetails) { }
}




export class GetBasicConnectionsList implements Action {
  readonly type = GlobalActionTypes.GetBasicConnectionsList;
}

export class GetBasicConnectionsListSuccess implements Action {
  readonly type = GlobalActionTypes.GetBasicConnectionsListSuccess;

  constructor(public payload: IBasicConnectionList[]) { }
}

export class GetBasicConnectionsListFault implements Action {
  readonly type = GlobalActionTypes.GetBasicConnectionsListFault;
  constructor(public payload: string) { }
}



export type GlobalActions =
  | GetGlobalSettings
  | GetGlobalSettingsSuccess
  | GetGlobalSettingsFault
  | SetBrandDetails
  | SetFormsList
  | GetBasicConnectionsList
  | GetBasicConnectionsListSuccess
  | GetBasicConnectionsListFault;
