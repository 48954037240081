import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Observable,
    of,
    throwError
} from "rxjs";
import {
    catchError,
    map
} from "rxjs/operators";
import {
    Auth,
    AuthResponse,
    LoginCredentials,
    OrganizationTypesReponseModel,
    RegisterCredentials,
    VerifyOtpCredentials
} from "../domain";
import { InvestTypesModel, InvestTypesModelResponse } from "../domain/investment-types.model";
import { ApiEndpointService } from "./api-endpoint.service";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import * as moment from 'moment';

@Injectable({
    providedIn: "root"
})
export class GlobalService {
    /**
     * Constructor.
     */
    constructor(private http: HttpClient, private toastService: ToastAlertService) {
    }

    getErrorMessage(fault, defaultMsg: string = 'Something went wrong') {
        let msg;
        if (fault?.error?.error) {
            msg = Array.isArray(fault?.error?.error) ? fault?.error?.error?.[0] : fault?.error?.message;
        }
        return msg || defaultMsg;
    }

    greetingMessage(){
      const myDate = new Date();
      const hrs = myDate.getHours();

      let greet;

      if (hrs < 12)
        greet = 'Good Morning';
      else if (hrs >= 12 && hrs <= 17)
        greet = 'Good Afternoon';
      else if (hrs >= 17 && hrs <= 24)
        greet = 'Good Evening';

        return greet;
    }

    /**
     * Attempt to get organization types.
     */
    public getOrganizationTypes(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ORGANIZATION_TYPE);
        return this.http.get(url).pipe(
            map((response: any): OrganizationTypesReponseModel => {
                return response.data
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }

    public getInvesmentTypes(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ORGANIZATION_INVESTMENT_TYPE);
        return this.http.get(url).pipe(
            map((response: any): InvestTypesModel => {
                return response.data
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }


    public searchLocations(searchTerm: string, ): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.LOCATION_SEARCH + searchTerm);
        return this.http.get(url).pipe(
            map((response: any): any => {
                return response.data || [];
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }

    public searchCity(searchTerm: string, ): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL+'cities/' + searchTerm+'/search');
      return this.http.get(url).pipe(
          map((response: any): any => {
              return response.data || [];
          }),
          catchError((fault: HttpErrorResponse) => {
              return throwError(() => fault);
          })
      );
  }

  public searchState(searchTerm: string, ): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL+'states/' + searchTerm+'/search');
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
}


    public govermentGrants(): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GOVERMENT_GRANTS);
      return this.http.get(url).pipe(
          map((response: any): any => {
              return response.data || [];
          }),
          catchError((fault: HttpErrorResponse) => {
              return throwError(() => fault);
          })
      );
  }

    public searchSkills(searchTerm: string): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.SKILLS_SEARCH + searchTerm);
        return this.http.get(url).pipe(
            map((response: any): any => {
                return response.data || [];
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }

    public verifyInviteCode(): Observable<any> {
      let inviteCode = localStorage.getItem('inviteCode');
      // let inviteCode = 'any';
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL) + 'verify-invite-code/'+ inviteCode;
      return this.http.get(url).pipe(
          map((response: any): InvestTypesModel => {
              return response
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(this.getErrorMessage(fault) || 'Error while fetching news', 'error');
              return throwError(() => fault);
          })
      );
  }

  public getPlatformEvents(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PLATFORM_EVENTS,true);
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }


  public getAllPlatformEvents(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PLATFORM_ALL_EVENTS,true);
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public getPlatformEventById(uuid): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PUBLIC_EVENTS,true) + uuid;
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public attendPlatformEventPublic(uuid, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PUBLIC_EVENTS,true) + uuid+'/attend';
    return this.http.post(url,payload).pipe(
        map((response: any): any => {
          this.toastService.showToast('Submitted your details ', 'success');
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }


  public getPlatformEventsAttending(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.EVENTS,true) + 'attend';
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }


  // public eventResponse(eventId: string, response: string): Observable<any> {
  //   const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.EVENTS) + `attend/${eventId}/${response}`;
  //   return this.http.post(url, {}).pipe(
  //       map((response: any): any => {
  //           return response.data || [];
  //       }),
  //       catchError((fault: HttpErrorResponse) => {
  //           return throwError(() => fault);
  //       })
  //   );
  // }

  public bookEventSlot(eventId: string,timeFrom?:string, timeTo?:string, action = 'attending', meetingAgenda = '', date=''): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.EVENTS,true) + `attend/${eventId}`;
    return this.http.post(url, {
      timeFrom,
      timeTo,
      "responseAction": action,
      offset: '' + new Date().getTimezoneOffset(),
      timeZone: moment.tz.guess(),
      meetingAgenda,
      date: date||null
    }).pipe(
        map((response: any): any => {
          if(action !== 'tentative' && action !== 'declined') {
            this.toastService.showToast('Slot requested')
          }
          return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public updateWebinarEventBooking(eventId: string, attendenceId:string,action = 'attending'): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.EVENTS,true) + `attend/${eventId}/${attendenceId}`;
    return this.http.patch(url, {
        "responseAction": action,
        offset: '' + new Date().getTimezoneOffset(),
        timeZone: moment.tz.guess(),
    }).pipe(
        map((response: any): any => {
            return response.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public getCountries(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COUNTRIES);
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }
  public getStates(country): Observable<any> {
    if(!country) {
      return of([])
    }
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STATES) + country;
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }
  public getCities(state): Observable<any> {
    if(!state) {
      return of([])
    }
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CITY) + state;
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public getAllowdedUserSearchTypes(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER)+ 'allowed-search-user-types';
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public platformFeedback(payload: {ratings: string,  comments: string} ): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL) +'platform-feedback'
    return this.http.post(url, payload).pipe(
        map((response: any): any => {
          this.toastService.showToast(response.message);
            return response;
        }),
        catchError((fault: HttpErrorResponse) => {
            this.toastService.showToast(this.getErrorMessage(fault, 'Error while saving feedback!'), 'error');
            return throwError(() => fault);
        })
    );
  }

  public getTicketTypes(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL)+ 'ticket_issue_types';
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public getWebinars(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL)+ 'webinars';
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public getReports(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL)+ 'report_downloads';
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public getProductUpdates(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL)+ 'product_updates';
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

  public getDeeptechNews(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL)+ 'news';
    return this.http.get(url).pipe(
        map((response: any): any => {
            return response.data || [];
        }),
        catchError((fault: HttpErrorResponse) => {
            return throwError(() => fault);
        })
    );
  }

}
